import LinkComponent from '../LinkComponent/LinkComponent'
import Image from '../Image/Image'
import MarkdownText from '../MarkdownText/MarkdownText'
import './style.scss'

interface ICardInfo {
  description: string
  href?: string
  className?: string
  smallerLogo?: string
  cardHeaderText: string
  logoAlt?: string
}

const CardInfo = (props: ICardInfo) => {
  const { description, href, className, smallerLogo, cardHeaderText, logoAlt } =
    props

  const content = (
    <div className={`infoCard ${className}`}>
      <div className="infoCard__header">
        {cardHeaderText}
        {smallerLogo && (
          <Image
            src={smallerLogo}
            alt={logoAlt}
            className="infoCard__logo"
            data-testid="card-info-img"
          />
        )}
      </div>
      <MarkdownText className="infoCard__description" text={description} />
    </div>
  )

  return href ? <LinkComponent href={href}>{content}</LinkComponent> : content
}

export default CardInfo
