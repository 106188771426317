import PermaLink from '../PermaLink/PermaLink'

import './style.scss'

const TitleBorder = ({
  titleText,
  className,
}: {
  titleText: string
  className?: string
}) => {
  return (
    <h2 className={`title-border ${className}`}>
      {titleText}
      <PermaLink title={titleText} />
    </h2>
  )
}

export default TitleBorder
